import type { LayoutProps } from '../../types';
import * as React from 'react';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import { useAppContext } from '../../contexts/AppContext';
import { useTheme } from '@mui/material/styles';
import { AggressiveHomeTopAds } from './AggressiveHomeTopAds';
import { AggressiveHomeBottomAds } from './AggressiveHomeBottomAds';
import {
  AGGRESSIVE_HOME_LEFT_ADS_CONFIGURATION,
  AGGRESSIVE_HOME_LEFT_MIDDLE_ADS_CONFIGURATION,
  AGGRESSIVE_HOME_RIGHT_ADS_CONFIGURATION,
  AGGRESSIVE_HOME_RIGHT_MIDDLE_ADS_CONFIGURATION,
} from '../../types/static';
import { AdsLeft, AdsLeftMiddle, AdsRight, AdsRightMiddle, Root, Sides } from '../../components/LayoutElements';

const RootColumn = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  height: 'auto',
}));

const SectionMiddle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexWrap: 'nowrap',
  height: 'auto',
  justifyContent: 'center',
  maxWidth: 1920,
  padding: theme.spacing(0, 2),
  overflow: 'hidden',
  [theme.breakpoints.down(900)]: {
    maxWidth: '100%',
    padding: theme.spacing(0, 1),
  },
}));

export const AggressiveHome = (props: LayoutProps) => {
  const { children } = props;
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();
  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.up('xl'));
  // @ts-ignore
  const xxl = useMediaQuery(theme.breakpoints.up('xxl'));

  return (
    <RootColumn>
      <Root>
        {!componentOptions.component_layout_left_ads_disabled && xl && (
          <Sides isLeft={true}>
            <AdsLeft configuration={AGGRESSIVE_HOME_LEFT_ADS_CONFIGURATION} slotIdentifier={componentOptions.component_aggressive_home_left_id || ''} variant="300x600" />
            {!componentOptions.component_layout_left_middle_ads_disabled && (
              <AdsLeftMiddle configuration={AGGRESSIVE_HOME_LEFT_MIDDLE_ADS_CONFIGURATION} slotIdentifier={componentOptions.component_aggressive_home_left_middle_id || ''} variant="300x250" />
            )}
          </Sides>
        )}
        <SectionMiddle>
          {!componentOptions.component_top_ads_disabled && <AggressiveHomeTopAds />}
          {children}
        </SectionMiddle>
        {!componentOptions.component_layout_right_ads_disabled && xxl && (
          <Sides shouldHideOnSmallScreens={true} isLeft={false}>
            <AdsRight configuration={AGGRESSIVE_HOME_RIGHT_ADS_CONFIGURATION} slotIdentifier={componentOptions.component_aggressive_home_right_id || ''} variant="300x600" />
            {!componentOptions.component_layout_right_middle_ads_disabled && (
              <AdsRightMiddle
                configuration={AGGRESSIVE_HOME_RIGHT_MIDDLE_ADS_CONFIGURATION}
                slotIdentifier={componentOptions.component_aggressive_home_right_middle_id || ''}
                variant="300x250"
              />
            )}
          </Sides>
        )}
      </Root>
      {!componentOptions.component_bottom_ads_disable && <AggressiveHomeBottomAds />}
    </RootColumn>
  );
};
