import styled from '@emotion/styled';
import dynamic from 'next/dynamic';

const AdvertisementSlot = dynamic<any>(
  () => import('../../components/AdvertisementSlot').then((mod) => mod.AdvertisementSlot),
  {
    ssr: false,
  }
);

export const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  height: 'auto',
  justifyContent: 'space-between',
}));

export const Sides = styled('div')<{ shouldHideOnSmallScreens?: boolean; isLeft?: boolean }>(({ theme, shouldHideOnSmallScreens, isLeft }) => ({
  display: 'none',
  [theme.breakpoints.up("lg")]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexWrap: 'nowrap',
    height: 'fit-content',
    justifyContent: 'center',
    padding: isLeft ? theme.spacing(7, 0, 2, 2) : theme.spacing(7, 2, 2, 0),
    top: 50,
    position: 'sticky',
  },
  ...(shouldHideOnSmallScreens && {
    [theme.breakpoints.down(1500)]: {
      display: 'none',
    },
  }),
}));

const AdvertisementSlotStyled = styled(AdvertisementSlot)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  margin: theme.spacing(0, 0, 4),
  width: 300,
}));

export const AdsLeft = ({ slotIdentifier, configuration, variant }: { slotIdentifier: string; configuration: any; variant: string }) => {
  return (
    <AdvertisementSlotStyled
      configuration={configuration}
      slotIdentifier={slotIdentifier}
      elevation={1}
      variant={variant}
      alignment="right"
    />
  );
};

export const AdsLeftMiddle = ({ slotIdentifier, configuration, variant }: { slotIdentifier: string; configuration: any; variant: string }) => {
  return (
    <AdvertisementSlotStyled
      configuration={configuration}
      slotIdentifier={slotIdentifier}
      variant={variant}
      alignment="right"
      elevation={1}
    />
  );
};

export const AdsRight = ({ slotIdentifier, configuration, variant }: { slotIdentifier: string; configuration: any; variant: string }) => {
  return (
    <AdvertisementSlotStyled
      configuration={configuration}
      slotIdentifier={slotIdentifier}
      elevation={1}
      variant={variant}
      alignment="left"
    />
  );
};

export const AdsRightMiddle = ({ slotIdentifier, configuration, variant }: { slotIdentifier: string; configuration: any; variant: string }) => {
  return (
    <AdvertisementSlotStyled
      configuration={configuration}
      slotIdentifier={slotIdentifier}
      variant={variant}
      alignment="left"
      elevation={1}
    />
  );
};